import { createEditor, Descendant } from 'slate';

export const createTsPdfEditor = () => {

  let editor = createEditor();

  const { isBlock, isInline } = editor;
  
  editor.isInline = element => {
    return element.type === 'code-block' ? true : isInline(element)
  }  

  editor.isBlock = element => {
    return element.type === 'table-cell' ? true : isBlock(element)
  }

  return editor;
}

export type CodeBlockElement = {
  type: 'code-block';
  key?: string;
  value?: string;
  converterType?: null,
  enums?: null,
  script?: null,
  language?: string; // Optional field to specify the programming language
  //@ts-ignore
  children: Descendant[];
};

//@ts-ignore
export type DataMapElement = CodeBlockElement | { type: 'paragraph', children: Descendant[] };