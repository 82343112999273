import './pdf-builder-page.css';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { BaseEditor, Editor, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, Editable, ReactEditor, withReact, useSlate, useSelected } from 'slate-react';

import { Button, ConfigProvider, Flex, Typography, notification } from 'antd';
import { AiOutlineNodeIndex } from "react-icons/ai";
import { pageHeaderContainerStyle, pageHeaderTitleStyle } from './page-style';
import { createTsPdfEditor, DataMapElement } from './types';
import { BlockRenderer, LeafRenderer } from './renderer';
import { useLazyGetClientQuery } from '../../redux/api/clients';
import { useLazyGetDataFieldsQuery, useLazyGetDataMapQuery, useSaveDataMapMutation } from '../../redux/api/data-maps';
import MainContainerLayout from '../../layout/pdf-layout/main-container-layout';

import { Client } from '../../model/client-model';
import { DataMapModel } from '../../model/data-map-model';
import { FaAlignCenter, FaAlignJustify, FaAlignLeft, FaAlignRight, FaBold, FaBorderAll, FaBorderNone, FaFont, FaItalic, FaListOl, FaPersonWalkingDashedLineArrowRight, FaTable, FaUnderline } from 'react-icons/fa6';
import { BlockButton, FontSelection, MarkButton, SizeSelection, withTables } from './components';
import { FaListUl, FaSave } from 'react-icons/fa';
import { TableAttributeModal } from './table-attribute-modal';

const { Text } = Typography;

interface Props { }

type CustomElement = { type: 'paragraph'; children: CustomText[] }
type CustomText = { text: string; bold?: true }

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor
    Element: CustomElement & DataMapElement
    Text: CustomText
  }
}

const nodeTypes = ['paragraph', 'table', 'table-row', 'table-cell'];

const PDFBuilderPage: React.FC<Props> = (): JSX.Element => {

  const [client, setClient] = useState<Client>();
  const [editing, setEditing] = useState<boolean>(false);
  const [tableModalOpen, setTableModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataMapFields, setDataMapFields] = useState<any[]>([]);
  const [content, setContent] = useState<any[]>();
  
  const editor = useMemo(() => withTables(withHistory(withReact(createTsPdfEditor()))), []);
  
  const selected = useSelected();
  const { clientId } = useParams();
  
  const [getClient] = useLazyGetClientQuery();
  const [getDataMapFields] = useLazyGetDataFieldsQuery();
  const [getDataMap] = useLazyGetDataMapQuery();
  const [saveDataMap] = useSaveDataMapMutation();

  useEffect(() => {
        
    const fetchDataMaps = async () => {

      const initializeDocument = (nodes?: DataMapModel[]): any => {
        
        const children = nodes?.map((item: DataMapModel) => {
          
          let data:any = {key: item.key};          
          if (item.type === 'NODE') {
            const type = typeToSlateTag(item.type, item.target);
            data.type = type;
          } else if (item.type === 'STATIC_TEXT') {
            data.text = item.source;
          } else if (item.type === 'MAPPED_TEXT') {
            data = {...data,
              type: 'code-block',
              converterType: item.converterType,
              enums: item.enums,
              value: item.source,
              script: item.script
            }
          }

          const attributes = item.children ? item.children.filter((child:any) => child.type === 'ATTRIBUTE' ) : [];
          attributes.forEach((attribute: any) => {
            const items = attribute.source.split(';');
            items.forEach((item:string) => {
              if (item === 'text-align:center') {
                data.align = 'center';
              } else if (item === 'font-weight:bold') {
                data.bold = true;
              } else if (item === 'font-style:italic') {
                data.italic = true;
              } else if (item === 'text-decoration:underline') {
                data.underline = true;
              } else if (item.startsWith('width')) {
                data.width = item.split(":")[1];
              }
            });
          });

          if (item.type !== 'STATIC_TEXT' && item.children) {

            const nonAttributes: DataMapModel[] = item.children ? 
              item.children.filter((child:any) => child.type !== 'ATTRIBUTE' ) : [];

            let children = initializeDocument(nonAttributes);
            if (children.length === 0) {
              children.push({text: ''});
            }
            data.children = children;
          }
          return data;
        });

        return children;
      }

      try {
            const dataMap = await getDataMap(clientId).unwrap();
            if (dataMap && dataMap.children && dataMap.children.length > 0) {
                const data = initializeDocument(dataMap.children);
                setContent(data);
            } else {
              setContent([{
                type: 'paragraph',
                children: [{text: ''}]
              }]);
            }
        } catch (e) {
            console.log(e);
            notification.error({
                message: "Data Map Configuration Error!",
                description: `An error occurred while fetching the data map configuration.`
            });
        }
      }
  
    if (dataMapFields.length !== 0) {
        fetchDataMaps();
    }

  }, [dataMapFields])

  useEffect(() => {

    const fetchDataMapFields = async () => {

      try {
        setLoading(true);
        const data = await getDataMapFields(clientId).unwrap();
        setDataMapFields(data.map((field: string) => {
          return { value: field, label: field };
        }));
        setLoading(false);
      } catch (e) {
        console.log(e);
        notification.error({
          message: "Data Fields Error!",
          description: `An error occurred while fetching the trusted driver data fields.`
        });
      }
    }

    const fetchClient = async () => {
      try {
        const data = await getClient(clientId).unwrap();
        setClient(data);
      } catch (e) {
        console.log(e);
        notification.error({
          message: "Clients Error!",
          description: `An error occurred while fetching the clients.`
        });
      }
    }

    fetchDataMapFields();
    fetchClient();

  }, []);

  const addDataMap = (editor: Editor, source: string) => {

    let node: DataMapElement = {
      type: 'code-block',
      key: crypto.randomUUID(),
      value: '',
      converterType: null,
      enums: null,
      script: null,
      children: [{ text: '' }], // Slate nodes must have children
    };

    Transforms.insertNodes(editor, node);
  };

  const addTable = (editor: Editor, value: any) => {

    let rowChildren = [];

    for (let i=0; i < value.rows; i++) {

      let colChildren = [];

      const width = `${(100 / value.cols).toFixed(2)}%`;
      for (let j=0; j < value.cols; j++) {
        let leafP = {type: 'paragraph', children: [{text: ''}]};
        colChildren.push({ type: 'table-cell', width, children: [leafP]});
      }

      let row = {
        type: 'table-row',
        children: colChildren,
      };

      rowChildren.push(row);
    }

    const tableNode = {
      type: 'table',
      width: '100%',
      children: rowChildren,
    };

    Transforms.insertNodes(editor, [
      tableNode,
      {type: 'paragraph', children: [{text: ''}]}
    ]);
    
    setTableModalOpen(false);
  }

  const onDocumentChange = (value: any) => {
    const isAstChange = editor.operations.some(
      op => 'set_selection' !== op.type
    )
    if (isAstChange) {
      setContent(value);
      setEditing(true);
    }
  }

  const saveTemplate = async () => {

    let body: DataMapModel = {
      type: 'NODE',
      key: crypto.randomUUID(),
      target: 'body',
      source: 'data',
      children: [],
    }

    body = convertToDataMap(content, body);

    const dataMap: DataMapModel = {
      client: client,
      children: [body]
    };

    try {
      await saveDataMap(dataMap).unwrap();
      setEditing(false);
    } catch (e) {
      console.log(e);
      notification.error({
          message: "Template Saving Error!",
          description: `An error occurred while saving the template.`
      });
    }
  }

  const convertToDataMap = (content: any, body: any) => {

    content.forEach((item:any) => {

      if (item.children) {  // Element Type

        let styles = '';
        if (item.align) styles += `text-align:${item.align};`;
        if (item.width) styles += `width:${item.width};`;
        if (item.type === 'paragraph') styles += 'min-height: 16px;';
        
        const styleAttribute: DataMapModel = {
          type: 'ATTRIBUTE',
          key: crypto.randomUUID(),
          target: 'style',
          source: styles,
        }

        if (item.type === 'code-block') {

          let child: DataMapModel = {
            type: 'MAPPED_TEXT',
            key: crypto.randomUUID(),
            target: 'span',
            source: item.value,
            children: styles.length > 0 ? [styleAttribute] : [],
          }
          child = convertToDataMap(item.children, child);
          body.children.push(child);

        } else if (nodeTypes.includes(item.type)) {

          let child: DataMapModel = {
            type: 'NODE',
            key: crypto.randomUUID(),
            target: typeToHtmlTag(item.type),
            children: styles.length > 0 ? [styleAttribute] : [],
          }
          child = convertToDataMap(item.children, child);
          body.children.push(child);

        }
      
      } else if (item.text) {  // Leaf Type

        let styles = '';
        if (item.bold) styles += 'font-weight:bold;';
        if (item.italic) styles += 'font-style:italic;';
        if (item.underline) styles += 'text-decoration:underline;';
        
        const styleAttribute: DataMapModel = {
          type: 'ATTRIBUTE',
          key: crypto.randomUUID(),
          target: 'style',
          source: styles,
        }

        const child: DataMapModel = {
          type: 'STATIC_TEXT',
          key: crypto.randomUUID(),
          target: 'span',
          source: item.text,
          children: styles.length > 0 ? [styleAttribute] : [],
        }

        body.children.push(child);
      }
    });
    return body;
  }

  const typeToHtmlTag = (type:string) => {

    switch (type) {
      case 'paragraph': return 'div';
      case 'table': return 'table';
      case 'table-row': return 'tr';
      case 'table-cell': return 'td';
      default: return 'div';
    }
  }

  const typeToSlateTag = (type:string | undefined, target:string | undefined, ) => {

    switch (type) {
      case 'NODE': 
        switch(target) {
          case 'div': return 'paragraph';
          case 'table': return 'table';
          case 'tr': return 'table-row';
          case 'td': return 'table-cell';
          default: return 'paragraph';
        }
      default: return null;
    }
  }

  const onKeyDown = (e: any) => {

    if (e.key === 'Enter') {
      // Prevent default behavior if necessary
      e.preventDefault();

      // Get the current selection
      const { selection } = editor;

      if (selection) {
        // // Get the currently selected node and its path
        // const [...nodeEntry] = Editor.nodes(editor, {
        //   match: (n: any) => {
        //     return Editor.isBlock(editor, n)
        //   }, // Match block-level nodes
        //   mode: 'all', // Get the highest matching node in the selection
        // });
        // if (nodeEntry) {
        //   const [node, path] = nodeEntry;
        //   Transforms.insertNodes(editor, {type: 'paragraph', children: [{text: ''}]});
        // }
        Transforms.insertNodes(editor, {type: 'paragraph', children: [{text: ''}]});
      }
    }
  }

  const renderElement = (props: any) => {

    const findElement = (key: string, content: any, path: number[]) => {

      if (content.key == key) return content;
      if (content.children) {
        for (let i=0; i < content.children.length; i++) {
          let child = content.children[i];
          let element:any = findElement(key, child, path);
          if (element) {
            path.unshift(i);
            return element;
          }
        }
      }
      return null;
    }

    const onDataMapChange = (key:string, value:any) => {

      if (content) {
        let path: number[] = [];
        let element:any;
        for (let i=0; i < content.length; i++) {
          element = findElement(key, content[i], path);
          if (element) {
            path.unshift(i);
            break;
          }
        }
        const newElement = {...element, ...value};
        Transforms.setNodes(editor, newElement, {at: path});
      }
    }

    const newProps = { ...props, dataSource: dataMapFields, onDataMapChange };
    return BlockRenderer(newProps);
  };

  const renderLeaf = useCallback(LeafRenderer, []);

  return (
    <ConfigProvider wave={{ disabled: true }}>
      <MainContainerLayout>
        <Flex style={pageHeaderContainerStyle}>
          <Text style={pageHeaderTitleStyle}>
            PDF Builder
          </Text>
        </Flex>
        <div className="editor-container">
          {content &&
          <Slate editor={editor} initialValue={content} onChange={onDocumentChange}>
            <div className="buttons-container">
              <div className="toolbar-container">
                <FontSelection/>
                <span className="divider"> | </span>
                <SizeSelection/>
                <span className="divider"> | </span>
                <MarkButton format="bold" icon={<FaBold />} />
                <MarkButton format="italic" icon={<FaItalic />} />
                <MarkButton format="underline" icon={<FaUnderline />} />
                <span className="divider"> | </span>
                <BlockButton format="left" icon={<FaAlignLeft />} />
                <BlockButton format="center" icon={<FaAlignCenter />} />
                <BlockButton format="right" icon={<FaAlignRight />} />
                <BlockButton format="justify" icon={<FaAlignJustify />} />
                <span className="divider"> | </span>
                <BlockButton format="numbered-list" icon={<FaListOl />} />
                <BlockButton format="bulleted-list" icon={<FaListUl />} />
                <span className="divider"> | </span>
                <Button
                  type="link"
                  style={{ color: '#555' }}
                  icon={<FaTable />}
                  onClick={() => setTableModalOpen(true)} />
                <BlockButton format="solid" icon={<FaBorderAll />} />
                <BlockButton format="dashed" icon={<FaBorderNone />} />
                <span className="divider"> | </span>
                <Button
                  type="link"
                  style={{ color: '#555' }}
                  icon={<AiOutlineNodeIndex />}
                  onClick={() => addDataMap(editor, '')} />
              </div>
              <div className="save-container">
                <Button 
                  disabled={!editing}
                  type="primary" 
                  icon={<FaSave/>}
                  onClick={saveTemplate}>
                  Save Template
                </Button>
              </div>
            </div>
            <div style={{ height: '70vh', paddingInline: '1rem', paddingBlock: '.1rem', overflow: 'auto' }}>
              <Editable style={{ minHeight: '65vh', outline: 'none'}}
                onKeyDown={onKeyDown}
                renderElement={renderElement}
                renderLeaf={renderLeaf} />
            </div>
          </Slate>
          }
          <TableAttributeModal 
            open={tableModalOpen} 
            onOk={(value:any) => addTable(editor, value)} 
            onCancel={() => setTableModalOpen(false)}/>
        </div>
      </MainContainerLayout>
    </ConfigProvider>
  )
}

export default PDFBuilderPage;