import { apiForPrivate } from '.';
import { SCRIPT_ENDPOINT } from '../../config/ap';

const apiScriptMaps = apiForPrivate.injectEndpoints({

  overrideExisting: true,
  
  endpoints: build => ({
    getScripts: build.query({
      query: () => SCRIPT_ENDPOINT.SCRIPTS,
    }),
    saveScript: build.mutation({
      query: (payload) => ({
        url: SCRIPT_ENDPOINT.SCRIPTS,
        method: 'POST',
        body: payload,
      }),
    }),
  })
});

export const {
  useLazyGetScriptsQuery,
  useSaveScriptMutation,
} = apiScriptMaps;