import { apiForPrivate } from '.';
import { CLIENT_ENDPOINT } from '../../config/ap';

const apiClients = apiForPrivate.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    getClient: build.query({
      query: id => `${CLIENT_ENDPOINT.CLIENTS}/${id}`,
    }),
    getClients: build.query({
      query: () => CLIENT_ENDPOINT.CLIENTS,
    }),
    addClient: build.mutation({
      query: payload => ({
        url: CLIENT_ENDPOINT.CLIENTS,
        method: 'POST',
        body: payload,
      }),
    }),
    updateClient: build.mutation({
      query: payload => ({
        url: CLIENT_ENDPOINT.CLIENTS,
        method: 'PUT',
        body: payload,
      }),
    }),
    copyClient: build.mutation({
      query: payload => ({
        url: `${CLIENT_ENDPOINT.CLIENTS}/copy`,
        method: 'POST',
        body: payload,
      }),
    }),
    deleteClient: build.mutation({
      query: payload => ({
        url: `${CLIENT_ENDPOINT.CLIENTS}/${payload.id}`,
        method: 'DELETE'
      }),
    }),
    scheduleClient: build.mutation({
      query: id => ({
        url: `${CLIENT_ENDPOINT.SCHEDULE_CLIENTS}/${id}`,
        method: 'POST',
      }),
    }),
    unscheduleClient: build.mutation({
      query: id => ({
        url: `${CLIENT_ENDPOINT.UNSCHEDULE_CLIENTS}/${id}`,
        method: 'POST',
      }),
    }),
  })
});

export const {
  useLazyGetClientQuery,
  useLazyGetClientsQuery,
  useAddClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useCopyClientMutation,
  useScheduleClientMutation,
  useUnscheduleClientMutation,
} = apiClients;