import { Form, Col, Input, Modal, Row } from "antd";
import { useEffect } from "react";

interface TableAttributeProps {
    open: boolean,
    onCancel: () => void,
    onOk: (values: any) => void
}

export const TableAttributeModal: React.FC<TableAttributeProps> = ({open, onOk, onCancel}): JSX.Element => {

    const [form] = Form.useForm();

    const saveTable = (values:any) => {
        onOk(values);
    }

    const failed = (err: any) => {
        console.log(err);
    }

    return (<Modal
        title={'Table Attributes'}
        closable={false}
        open={open}
        onCancel={onCancel}
        onOk={() => form.submit()}
        destroyOnClose={true}
        width={480}
    >
        <Form 
            form={form} 
            onFinish={saveTable} 
            onFinishFailed={failed}
            className="table-attributes-modal">
            
            <Row gutter={16}>
                <Col span={6}><label>Rows</label></Col>
                <Col span={6}>
                    <Form.Item
                        name="rows"
                        rules={[{required: true, whitespace: true, message: "*"}]}
                    >
                        <Input placeholder="2"/>
                    </Form.Item>
                </Col>
                <Col span={6}><label>Columns</label></Col>
                <Col span={6}>
                    <Form.Item
                        name="cols"
                        rules={[{required: true, whitespace: true, message: "*"}]}
                    >
                        <Input placeholder="2"/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Modal>)
}
