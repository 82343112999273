import { Button, Checkbox, Form, Input, Select, Space } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useEffect, useState } from "react";
import { Client } from "../../model/client-model";

const payloadTypes = [
    { label: 'Bar-delimited Values (BSV)', value: 'BSV' },
    { label: 'Comma-delimited Values (CSV)', value: 'CSV' },
    { label: 'Excel', value: 'EXCEL' },
    { label: 'JSON', value: 'JSON' },
    { label: 'XML', value: 'XML' },
    { label: 'PDF', value: 'PDF' },
    { label: 'Word', value: 'WORD' },
]
  
const channelTypes = [
    { label: 'No Channel', value: null },
    { label: 'Secure FTP', value: 'sFTP' },
    { label: 'SOAP', value: 'SOAP' },
    { label: 'REST', value: 'REST' },
    // { label: 'Apache Kafka', value: 'KAFKA' },
    // { label: 'MQTT', value: 'MQTT' },
    // { label: 'TCP / IP', value: 'TCP_IP' },
]

const ClientConfiguration = ({show, client, onClose, onNext, onChannelTypeChange}: any) => {

    const [transmit, setTransmit] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(client);
    }, [client]);
    
    const requiredRule = (isRequired: boolean, message: string) => {
        return {
          required: isRequired,
          message: message,
        }
    }

    const onFinish = (values: any) => {
        const data = {...client, ...values};
        onNext(data);
    }

    const toggleTransmission = (e: CheckboxChangeEvent) => {
        setTransmit(e.target.checked);
    }

    return <Form 
        form={form}
        className="clients-page-form"
        onFinish={onFinish}
        style={{overflow: 'hidden', height: show ? 'auto' : '0rem'}}
        >
  
        <h2>Client and Service Configuration</h2>
        
        <h3>General Settings</h3>
        <label>Name *</label>
        <Form.Item
            name="name"
            rules={[
            requiredRule(true, "Name is required.")
            ]}
        >
            <Input />
        </Form.Item>
    
        <label>Client Code *</label>
        <Form.Item
            name="clientCode"
            rules={[
            requiredRule(true, "Client code is required.")
            ]}
        >
            <Input />
        </Form.Item>
    
        <h3>Service Settings</h3>
        <Form.Item
            name="transmit"
            valuePropName="checked"
        >
            <Checkbox onChange={toggleTransmission}>Enable transmission</Checkbox>
        </Form.Item>
    
        <label>Payload Type *</label>
        <Form.Item
            name="payloadType"
            rules={[
                requiredRule(true, "Payload Type is required.")
            ]}
        >
            <Select options={payloadTypes} />
        </Form.Item>
        <label>Channel Type {transmit ? '*' : ''}</label>
        <Form.Item
            name="channelType"
            rules={[
                requiredRule(transmit, "Channel Type is required.")
            ]}
        >
            <Select options={channelTypes} onChange={onChannelTypeChange} />
        </Form.Item>
        
        <h3>Scheduling</h3>
        <label>Cron Schedule</label>
        <Form.Item
            name="cronSchedule"
        >
        <Input />
        </Form.Item>

        <Space direction="horizontal" align="end" style={{marginTop: '2rem', width: '100%', justifyContent: 'flex-end'}}>
            <Button type="default" key="cancel" onClick={onClose}>Cancel</Button>
            <Button type="primary" htmlType="submit">Proceed</Button>
        </Space>
    </Form>
}

export default ClientConfiguration;