import { Button, Form, Col, Input, Modal, Radio, Row, Select, Space, RadioChangeEvent, Checkbox } from "antd";
import { FaPlus } from "react-icons/fa6";
import { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { CheckboxChangeEvent } from "antd/es/checkbox";

interface ConverterProps {
    open: boolean,
    dataSource?: any[], 
    node?: any,
    value: string,
    onCancel: () => void,
    onOk: (values: any) => void
}

export const ConverterModal: React.FC<ConverterProps> = ({open, node, value, dataSource, onOk, onCancel}): JSX.Element => {

    const [valueField, setValueField] = useState<string>();
    const [converterType, setConverterType] = useState<string>('NONE');
    const [required, setRequired] = useState<boolean | undefined>(false);

    const [form] = Form.useForm();

    useEffect(() => {
        setValueField(value);
    }, [value]);
    
    useEffect(() => {
        setConverterType(node?.converterType);
        setRequired(node?.required);
        form.setFieldValue('enums', node?.enums);
        form.setFieldValue('script', node?.script);
        form.setFieldValue('required', node?.required);
        
    }, [node]);

    const saveConverter = (values:any) => {
        const convertedData = {...values, value: valueField, converterType, required};
        onOk(convertedData);
    }

    const onModeChange = (e: RadioChangeEvent) => {
        setConverterType(e.target.value);
    }

    const onValueChange = (value: string) => {
        setValueField(value);
    }

    const onRequiredChecked = (e: CheckboxChangeEvent) => {
        setRequired(e.target.checked);
    }

    return (<Modal
        title={'Converter'}
        closable={false}
        open={open}
        onCancel={onCancel}
        onOk={() => form.submit()}
        destroyOnClose={true}
        width={480}
    >
        <Form 
            form={form} 
            onFinish={saveConverter} 
            className="data-mapping-modal">
            <Select
                value={valueField}
                className="data-map-select"
                options={dataSource}
                onChange={onValueChange}
            />
            <div className="required-field">
                <Checkbox checked={required} onChange={onRequiredChecked}>This is a required field *</Checkbox>
            </div>
            <Radio.Group onChange={(e) => onModeChange(e)} value={converterType}>
                <Space direction="vertical" size="large" style={{width: '100%'}}>
                    <Radio value="NONE">No Conversion needed</Radio>
                    <Radio value={"ENUM"}>Enumeration Types</Radio>
                    {converterType === 'ENUM' && <Form.List name="enums">
                    {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field) => {
                        return (<Form.Item
                            required={false}
                            key={field.key}>
                            <Row gutter={8}>
                                <Col span={10}>
                                    <Form.Item
                                        name={[field.name, 'source']}
                                        validateTrigger={['onBlur']}
                                        rules={[{required: true, whitespace: true, message: "*"}]}
                                    >
                                        <Input placeholder="Source" autoComplete="off"/>
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        name={[field.name, 'target']}
                                        validateTrigger={['onBlur']}
                                        rules={[{required: true, whitespace: true, message: "*"}]}
                                    >
                                        <Input placeholder="Target" autoComplete="off"/>
                                    </Form.Item>
                                </Col>
                                
                                <Col span={4} className="flex flex-1 justify-center" style={{marginTop: '.25rem'}}>
                                    <Button 
                                        type="link" 
                                        onClick={() => {remove(field.name);}}
                                    >
                                        Remove
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>)}
                        )}
                        <Form.Item>
                            <Button
                                style={{marginTop: '1rem'}}
                                icon={<FaPlus/>}
                                type="link"
                                onClick={() => {
                                    add();
                                }}>
                                Add Enum Type
                            </Button>
                        </Form.Item>
                    </>
                    )}
                    </Form.List>}
                    <Radio value={"SCRIPT"}>Script</Radio>
                    {converterType === 'SCRIPT'
                    && <>
                        <Form.Item
                            required={true}
                            validateTrigger={['onBlur']}
                            rules={[{required: true, whitespace: true, message: "Script is required"}]}
                            name="script">
                            <TextArea style={{height: '8rem'}} placeholder="Script"/>
                        </Form.Item>
                    </>}
                    <br/>
                </Space>
            </Radio.Group>
        </Form>
    </Modal>)
}
