import './clients-page.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import _isUndefined from 'lodash/isUndefined';

import { Button, Dropdown, Form, Input, Modal, Space, Table, Tag, notification } from 'antd';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { AiOutlineCalendar, AiOutlineCopy, AiOutlineDelete, AiOutlineEdit, AiOutlineFilePdf } from 'react-icons/ai';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { PiTreeStructureLight } from "react-icons/pi";
import { addControlStyle } from './clients-page-style';
import { ClientForm } from './client-form';

import { Client } from '../../model/client-model';
import { DataMapSourceModel } from '../../model/data-map-source-model';

import { 
  useAddClientMutation, 
  useCopyClientMutation, 
  useDeleteClientMutation, 
  useLazyGetClientsQuery, 
  useScheduleClientMutation, 
  useUnscheduleClientMutation, 
  useUpdateClientMutation 
} from '../../redux/api/clients';

import { 
  useLazyGetDataMapSourceQuery, 
  useSaveDataMapSourceMutation 
} from '../../redux/api/data-maps';


const ClientsPage = () => {

  const updateMenu = {
    key: 'update',
    icon: <AiOutlineEdit />,
    label: 'Update Client'
  };

  const deleteMenu = {
    key: 'delete',
    icon: <AiOutlineDelete />,
    label: 'Delete Client'
  };

  const dataMappingMenu = {
    key: 'datamapping',
    icon: <PiTreeStructureLight />,
    label: 'Open Data Mapping'
  };

  const pdfBuilderMenu = {
    key: 'pdfbuilder',
    icon: <AiOutlineFilePdf />,
    label: 'Open PDF Builder'
  };

  const scheduleMenu = {
    key: 'schedule',
    icon: <AiOutlineCalendar />,
    label: 'Schedule Transmission'
  };

  const unscheduleMenu = {
    key: 'unschedule',
    icon: <AiOutlineCalendar />,
    label: 'Cancel Transmission'
  };

  const copyMenu = {
    key: 'copy',
    icon: <AiOutlineCopy />,
    label: 'Create Client Copy'
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 64,
    },
    {
      title: 'Client Code',
      dataIndex: 'clientCode',
      key: 'clientCode',
      width: 200,
      // sorter: (a: string, b: string) => a.localeCompare(b)
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      // sorter: (a: string, b: string) => a.localeCompare(b)
    },
    {
      title: 'Cron Schedule',
      dataIndex: 'cronSchedule',
      key: 'cronSchedule',
      width: 160,
    },
    {
      title: 'Payload',
      dataIndex: 'payloadType',
      key: 'payloadType',
      render: (payloadType: string) => payloadType ? payloadType.replace('_', ' ') : '',
      width: 104,
    },
    {
      title: 'Channel',
      dataIndex: 'channelType',
      key: 'channelType',
      render: (channelType: string) => channelType ? channelType.replace('_', ' ') : '',
      width: 104,
    },
    {
      title: 'Status',
      dataIndex: 'scheduled',
      key: 'scheduled',
      width: 120,
      render: (scheduled: boolean) => {
        return scheduled ? <Tag color="green">Scheduled</Tag> : <Tag color="red">Unscheduled</Tag>
      }
    },
    {
      title: 'Transmit',
      dataIndex: 'transmit',
      key: 'transmit',
      width: 104,
      render: (transmit: boolean) => {
        return transmit ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 96,
      render: (_: any, record: Client) => {
        return <Dropdown
          trigger={['click']}
          menu={{
            items: getMenuItems(record.cronSchedule, record.scheduled, record.payloadType),
            onClick: ({ key }) => handleClientAction(key, record)
          }}>
          <div style={{ cursor: 'pointer' }} onClick={(e) => e.preventDefault()}>
            <Space>
              <span className="dropdown-title">
                <BsThreeDotsVertical />
              </span>
            </Space>
          </div>
        </Dropdown>
      }
    },
  ];

  const [isAddOpen, setAddOpen] = useState<boolean>(false);
  const [isEditOpen, setEditOpen] = useState<boolean>(false);
  const [isScheduleOpen, setScheduleOpen] = useState<boolean>(false);
  const [isUnscheduleOpen, setUnscheduleOpen] = useState<boolean>(false);
  const [isCopyOpen, setCopyOpen] = useState<boolean>(false);
  const [clients, setClients] = useState<Client[]>([]);
  
  const [client, setClient] = useState<Client>();

  const [modal, contextHolder] = Modal.useModal();

  const [getClients] = useLazyGetClientsQuery();
  const [postClient] = useAddClientMutation();
  const [putClient] = useUpdateClientMutation();
  const [removeClient] = useDeleteClientMutation();
  const [postCopyClient] = useCopyClientMutation();
  const [postScheduleClient] = useScheduleClientMutation();
  const [postUnscheduleClient] = useUnscheduleClientMutation();
  const [getDataMapSource] = useLazyGetDataMapSourceQuery();
  const [saveDataMapSource] = useSaveDataMapSourceMutation();
  
  const navigate = useNavigate();

  const [newClientForm] = Form.useForm();

  useEffect(() => {
    const fetchClients = async () => {
      const clients = await getClients({}).unwrap();
      const indexedClients = clients.map((client: Client, index: number) => {
        return { ...client, key: index };
      });
      setClients(indexedClients);
    }
    fetchClients();
  }, [getClients]);

  /**
   * Gets the menu items available to the client depending on its current state
   * 
   * @param scheduled if scheduled
   * @param payloadType the payload type (XML, JSON, PDF, etc)
   * @returns the menu items
   */
  const getMenuItems = (cronSchedule: string, scheduled: boolean | undefined, payloadType: string | undefined ) => {
    return [
      updateMenu,
      deleteMenu,
      payloadType === 'PDF' ? pdfBuilderMenu : dataMappingMenu,
      cronSchedule ? (!scheduled ? scheduleMenu : unscheduleMenu) : null,
      copyMenu,
    ];
  }

  /**
   * Opens the Add Client Modal.
   */
  const openAddModal = (): void => {
    setAddOpen(true);
  }

  /**
   * Adds a client by sending the client data to the server.
   * 
   * @param client the client to add
   */
  const addClient = async (client: Client): Promise<void> => {

    try {

      const sourceData = client.sourceData;
      delete client.sourceData;
      const response = await postClient(client).unwrap();
      
      let newClients: Client[] = [...clients, response];
      setClients(newClients);
      
      await saveDataMapSource({clientId: response.id, source: sourceData});
      
      setAddOpen(false);

      notification.success({
        message: "Client Created!",
        description: `The client ${response.name} has been created.`
      });
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Creating Client Failed!",
        description: `An error occurred while creating the client`
      });
    }
  }

  const updateClient = async (client: Client): Promise<void> => {

    try {

      const sourceData = client.sourceData;
      delete client.sourceData;
      const response = await putClient(client).unwrap();
      
      let ndx = clients.findIndex(client => client.id === response.id);
      clients[ndx] = response;
      let newClients: Client[] = [...clients];
      setClients(newClients);

      await saveDataMapSource({clientId: response.id, source: sourceData});
      
      setEditOpen(false);

      notification.success({
        message: "Client Update!",
        description: `The client ${response.name} has been update.`
      });
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Updating Client Failed!",
        description: `An error occurred while creating the client`
      });
    }
  }

  const deleteClient = async (client: Client): Promise<void> => {
    try {
      const response = await removeClient(client).unwrap();

      const temp = [...clients];
      let ndx = temp.findIndex(client => client.id === response.id);
      temp.splice(ndx, 1);
      let newClients: Client[] = [...temp];
      setClients(newClients);
      notification.success({
        message: "Client Deleted!",
        description: `The client ${client.name} has been deleted.`
      });

    } catch (e) {
      console.log(e);
      notification.error({
        message: "Deleting Client Failed!",
        description: `An error occurred while deleting the client.`
      });
    }
  }

  /**
   * Opens the client's data mapping page.
   */
  const openDataMappingPage = (client: Client) => {
    navigate(`/data-mapping/${client?.id}`);
  }

  /**
   * Opens the pdf bulder page.
   */
  const openPdfBuilderPage = (client: Client) => {
    navigate(`/pdf-builder/${client?.id}`);
  }

  /**
   * Schedules a client for fetching data and sending to target client.
   */
  const scheduleClient = async () => {

    try {
      const response = await postScheduleClient(client?.id).unwrap();
      const index = clients?.findIndex((client: any) => client.id === response.id);
      let newClients: Client[] = [...clients];
      newClients[index].scheduled = true;
      setClients(newClients);
      setScheduleOpen(false);
      notification.success({
        message: "Client Scheduled!",
        description: `The client ${response.name} has been scheduled for dispatching.`
      });
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Scheduling Client Failed!",
        description: `An error occurred while scheduling the client`
      });
    }
  }

  /**
   * Cancels the scheduled client from fetching data and sending to target client.
   */
  const unscheduleClient = async () => {

    try {
      const response = await postUnscheduleClient(client?.id).unwrap();
      const index = clients?.findIndex((client: any) => client.id === response.id);
      let newClients: Client[] = [...clients];
      newClients[index].scheduled = false;
      setClients(newClients);
      setUnscheduleOpen(false);
      notification.success({
        message: "Client Schedule Canceled!",
        description: `The schedule of client ${response.name} has been canceled.`
      });
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Cancel Client Schedule Failed!",
        description: `An error occurred while cancelling the client's schedule. ${e}`
      });
    }
  }

  const copyClient = async (value: any) => {
    try {

      const response = await postCopyClient({
        clientId: client?.id, 
        clientCode: value.clientCode, 
        name: value.name
      }).unwrap();

      let newClients: Client[] = [response, ...clients];
      setClients(newClients);
      setCopyOpen(false);
      notification.success({
        message: "Create Client Copy Canceled!",
        description: `The operation to copy a client ${response.name} has been canceled.`
      });
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Cancel Client Schedule Failed!",
        description: `An error occurred while copying the client. ${e}`
      });
    }
  }

  /**
   * Cancels the schedule client confirmation modal.
   */
  const closeScheduleModal = (): void => {
    setScheduleOpen(false);
  }

  /**
   * Cancels the client schedule canceling confirmation modal.
   */
  const closeUnscheduleModal = (): void => {
    setUnscheduleOpen(false);
  }

  /**
   * Cancels the create client copy modal.
   */
  const closeCopyModal = (): void => {
    setCopyOpen(false);
  }

  const onEditOpen = async (client: Client) => {
    const source: DataMapSourceModel = await getDataMapSource(client.id).unwrap();
    const data = {...client, sourceData: source?.source};
    setClient(data);
    setEditOpen(true);
  }

  const onDelete = (client: Client) => {
    
    modal.confirm({
      title: 'Delete the client',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to delete this client?',
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: () => deleteClient(client),
    });
  };

  /**
   * Handles the click action on the action button of the client
   * in the client list (the 3-dot vertical button on the right-most
   * column)
   * 
   * @param key the key of the menu item that was clicked
   * @param client the client that was clicked
   */

  const handleClientAction = (key: string, client: Client) => {
    setClient(client);
    switch (key) {
      case 'update': onEditOpen(client); break;
      case 'delete': onDelete(client); break;
      case 'datamapping': openDataMappingPage(client); break;
      case 'pdfbuilder': openPdfBuilderPage(client); break;
      case 'schedule': setScheduleOpen(true); break;
      case 'unschedule': setUnscheduleOpen(true); break;
      case 'copy': setCopyOpen(true); break;
    }
  }

  return (
    <>
      {contextHolder}
      <div style={addControlStyle}>
        <h1>Clients</h1>
        <Button
          type="primary"
          onClick={openAddModal}
        >
          Add Client
        </Button>
      </div>

      <Table dataSource={clients} columns={columns} />

      <Modal
        closable={false}
        open={isAddOpen}
        width={600}
        footer={null}
        destroyOnClose={true}
      >
        <ClientForm 
          onClose={() => setAddOpen(false)}
          addClient={addClient}
        />
      </Modal>

      <Modal
        title={'Client Information'}
        closable={false}
        open={isEditOpen}
        width={600}
        footer={null}
        destroyOnClose={true}
      >
        <ClientForm 
          client={client}
          onClose={() => setEditOpen(false)}
          updateClient={updateClient}
        />
      </Modal>

      <Modal
        title={'Schedule Client'}
        closable={false}
        open={isScheduleOpen}
        onOk={scheduleClient}
        onCancel={closeScheduleModal}
        width={600}
      >
        <p>Are you sure you want to schedule the client <span style={{ color: 'green' }}>[{client?.name}]</span> for dispatching?</p>
      </Modal>

      <Modal
        title={'Cancel Client Schedule'}
        closable={false}
        open={isUnscheduleOpen}
        onOk={unscheduleClient}
        onCancel={closeUnscheduleModal}
        width={600}
      >
        <p>Are you sure you want to cancel the schedule for client <span style={{ color: 'green' }}>[{client?.name}]</span>?</p>
      </Modal>

      <Modal
        title={'Create Client Copy'}
        closable={false}
        open={isCopyOpen}
        onOk={() => newClientForm.submit()}
        onCancel={closeCopyModal}
        width={400}
        destroyOnClose={true}
      >
          <Form 
            form={newClientForm}
            onFinish={copyClient}
            >
            <label>Name *</label>
            <Form.Item
                name="name"
                rules={[{required: true, message: 'Name is required.'}]}
            >
                <Input />
            </Form.Item>
        
            <label>Client Code *</label>
            <Form.Item
                name="clientCode"
                rules={[{required: true, message: 'Client code is required.'}]}
            >
                <Input />
            </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default ClientsPage;

