import { Button, Select } from "antd";
import { useState } from "react";
import { Editor, Element, Range, Transforms, Point } from "slate";
import { useSlate } from "slate-react";

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];
const TABLE_BORDER_TYPES = ['solid', 'dashed']

const fonts = [
    {value: 'arial', label: 'Arial'},
    {value: 'brush_script_mt', label: 'Brush Script MT'},
    {value: 'courier_new', label: 'Courier New'},
    {value: 'garamond', label: 'Garamond'},
    {value: 'georgia', label: 'Georgia'},
    {value: 'tahoma', label: 'Tahoma'},
    {value: 'times_new_roman', label: 'Times New Roman'},
    {value: 'trebuchet_ms', label: 'Trebuchet MS'},
    {value: 'verdana', label: 'Verdana'},
];

const sizes = [
   {value: 'sz6', label: '6'},
   {value: 'sz7', label: '7'},
   {value: 'sz8', label: '8'},
   {value: 'sz9', label: '9'},
   {value: 'sz10', label: '10'},
   {value: 'sz11', label: '11'},
   {value: 'sz12', label: '12'},
   {value: 'sz14', label: '14'},
   {value: 'sz16', label: '16'},
   {value: 'sz18', label: '18'},
   {value: 'sz20', label: '20'},
   {value: 'sz22', label: '22'},
   {value: 'sz24', label: '24'},
   {value: 'sz30', label: '30'},
];

/**
 * Component for setting the font of a selection.
 * 
 * @returns the component for setting font
 */
export const FontSelection = () => {

   const [value, setValue] = useState<string>('Arial');
   const editor = useSlate();
   
   const onChange = (newFont: string) => {
      
      Editor.removeMark(editor, 'arial');
      Editor.removeMark(editor, 'brush_script_mt');
      Editor.removeMark(editor, 'courier_new');
      Editor.removeMark(editor, 'garamond');
      Editor.removeMark(editor, 'georgia');
      Editor.removeMark(editor, 'tahoma');
      Editor.removeMark(editor, 'times_new_roman');
      Editor.removeMark(editor, 'trebuchet_ms');
      Editor.removeMark(editor, 'verdana');
      
      Editor.addMark(editor, newFont, true);
      setValue(newFont);
   }

   return (
      <Select 
         value={value} 
         style={{width: 144, marginRight: '.5rem'}} 
         options={fonts}
         onChange={onChange}/>
   )
}

/**
 * Component for setting the font of a selection.
 * 
 * @returns the component for setting font
 */
export const SizeSelection = () => {

   const [value, setValue] = useState<string>('16');
   const editor = useSlate();
   
   const onChange = (newSize: string) => {
      
      Editor.addMark(editor, newSize, true);
      setValue(newSize);
   }

   return (
      <Select 
         suffixIcon={null}
         value={value} 
         style={{width: 64, marginInline: '.5rem'}} 
         options={sizes}
         onChange={onChange}/>
   )
}

/**
 * Component for setting the style of a selection.
 * Style can be bold, italic, underline or a combination of the 3.
 * 
 * @param param0 
 * @returns 
 */
export const MarkButton = ({ format, icon }: any) => {

   const editor = useSlate();
   const isActive = isMarkActive(editor, format)

   return (
      <Button
         type={isActive ? 'default' : 'link'}
         icon={icon}
         style={{ color: '#555' }}
         onClick={event => {
            event.preventDefault()
            toggleMark(editor, format)
         }} />
   )
}

/**
 * Formats the display of the block.
 * 
 * @param param0 
 * @returns 
 */
export const BlockButton = ({ format, icon }: any) => {

   const editor = useSlate();
   const isActive = isBlockActive(editor, format, 
      TEXT_ALIGN_TYPES.includes(format) ? 'align' : 
      TABLE_BORDER_TYPES.includes(format) ? 'border' : 'type');

   return (
      <Button
         type={isActive ? 'default' : 'link'}
         icon={icon}
         style={{ color: '#555' }}
         onClick={event => {
            event.preventDefault()
            toggleBlock(editor, format)
         }} />
   )
}

const toggleMark = (editor: Editor, format: string) => {

   const isActive = isMarkActive(editor, format);

   if (isActive) {
      Editor.removeMark(editor, format)
   } else {
      Editor.addMark(editor, format, true)
   }
}

const isMarkActive = (editor: Editor, format: any) => {

   const marks = Editor.marks(editor);
   //@ts-ignore
   return marks ? marks[format] === true : false;
}

const isBlockActive = (editor: Editor, format: string, blockType = 'type') => {

   const { selection } = editor;
   if (!selection) return false;

   const [match] = Array.from(
      Editor.nodes(editor, {
         at: Editor.unhangRange(editor, selection),
         match: n =>
            !Editor.isEditor(n) &&
            Element.isElement(n) &&
            n[blockType] === format,
      })
   )

   return !!match;
}

const toggleBlock = (editor: Editor, format: string) => {

   const isActive = isBlockActive(editor, format, 
      TEXT_ALIGN_TYPES.includes(format) ? 'align' : 
      TABLE_BORDER_TYPES.includes(format) ? 'border' : 'type');

   const isList = LIST_TYPES.includes(format);

   Transforms.unwrapNodes(editor, {
      match: n =>
         !Editor.isEditor(n) &&
         Element.isElement(n) &&
         LIST_TYPES.includes(n.type) &&
         !TEXT_ALIGN_TYPES.includes(format) &&
         !TABLE_BORDER_TYPES.includes(format) ,
      split: true,
   });

   let newProperties: Partial<Element>
   if (TEXT_ALIGN_TYPES.includes(format)) {
      newProperties = {
         align: isActive ? undefined : format,
      }
   } else if (TABLE_BORDER_TYPES.includes(format)) {
      newProperties = {
         border: isActive ? undefined : format,
      }
   } else {
      newProperties = {
         type: isActive ? 'paragraph' : isList ? 'list-item' : format,
      }
   }
   Transforms.setNodes<Element>(editor, newProperties);

   if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
   }
}


 
export const withTables = (editor: Editor) => {
   const { deleteBackward, deleteForward, insertBreak } = editor

   editor.deleteBackward = unit => {
      const { selection } = editor

      if (selection && Range.isCollapsed(selection)) {
         const [cell]: any = Editor.nodes(editor, {
            match: n =>
               !Editor.isEditor(n) &&
               Element.isElement(n) &&
               n.type === 'table-cell',
         })

         if (cell) {
            const [, cellPath] = cell
            const start = Editor.start(editor, cellPath)

            if (Point.equals(selection.anchor, start)) {
               return
            }
         }
      }

      deleteBackward(unit)
   }

   editor.deleteForward = unit => {
      const { selection } = editor

      if (selection && Range.isCollapsed(selection)) {
         const [cell]: any = Editor.nodes(editor, {
            match: n =>
               !Editor.isEditor(n) &&
               Element.isElement(n) &&
               n.type === 'table-cell',
         })

         if (cell) {
            const [, cellPath] = cell
            const end = Editor.end(editor, cellPath)

            if (Point.equals(selection.anchor, end)) {
               return
            }
         }
      }

      deleteForward(unit)
   }

   editor.insertBreak = () => {
      const { selection } = editor

      if (selection) {
         const [table]: any = Editor.nodes(editor, {
            match: n =>
               !Editor.isEditor(n) &&
               Element.isElement(n) &&
               n.type === 'table',
         })

         if (table) {
            return
         }
      }

      insertBreak()
   }

   return editor
}