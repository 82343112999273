import { useEffect, useState } from "react";
import { ConverterModal } from "./converter-modal";

const fontMap = {
  ARIAL: 'Arial', 
  BRUSH_SCRIPT_MT: 'Brush Script MT',
  COURIER_NEW: 'Courier New',
  GARAMOND: 'Garamond',
  GEORGIA: 'Georgia',
  TAHOMA: 'Tahoma', 
  TIMES_NEW_ROMAN: 'Times New Roman',
  TREBUCHET_MS: 'Trebuchet MS',
  VERDANA: 'Verdana'
}

export const DefaultBlockRenderer = (props: any) => {
  return <p {...props.attributes}>{props.children}</p>
}

export const CodeBlockRenderer = ({value, dataSource, onChange}: any) => {

  const [display, setDisplay] = useState<string>();
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    const fields = value.value.split(".");
    setDisplay(fields[fields.length - 1]);
  }, [value])

  const onOk = (value: any) => {
    const fields = value.value.split(".");
    setDisplay(fields[fields.length - 1]);
    if (onChange) onChange(value);
    setEditMode(false);
  }

  const onCancel = () => {
    setEditMode(false);
  }

  return <>
    <code 
      className="data-map-container" 
      onClick={() => {setEditMode(true)}}>
      {`${display ? display: '<set data>'}`}
    </code>
    <ConverterModal value={value} dataSource={dataSource} open={editMode} onOk={onOk} onCancel={onCancel}/>
  </>
}

export const LeafRenderer = ({ attributes, children, leaf }: any) => {

  let style: any = {...styleFontFamily(leaf), ...styleFontSize(leaf)};
  
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes} style={style}>{children}</span>
}

export const BlockRenderer = ({ element, children, attributes, dataSource, onDataMapChange }: any) => {

  let style:any = { textAlign: element.align };
  const font = element.type.startsWith('font') ? element.type.substring(5) : null;
  if (font) {
    style.fontFamily = font;
  }

  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      )
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    case 'code-block':

      return <CodeBlockRenderer
        key={element.key}
        value={element}
        dataSource={dataSource}
        onChange={(value:string) => onDataMapChange(element.key, value)}/>
        
    case 'table':
      return (
        <table
          {...attributes}
          style={{
            borderCollapse: 'collapse',
            width: element.width,
          }}
        >
          <tbody>{children}</tbody>
        </table>
      )
    case 'table-row':
      return (
        <tr
          {...attributes}
          style={{
            borderBottom: element.border ? `1px ${element.border} black` : '1px dotted #ddd'
          }}
        >
          {children}
        </tr>
      );
    case 'table-cell':
      return (
        <td
          {...attributes}
          style={{
            border: element.border ? `1px ${element.border} black` : '1px dotted #ddd',
            width: element.width,
            padding: '8px'
          }}
        >
          {children}
        </td>
      )
    default:
      return (
        <div style={style} {...attributes}>
          {children}
        </div>
      )
  }
}

const styleFontFamily = (leaf: any) => {

  let style: any = {};
  if (leaf.arial) {
    style.fontFamily = fontMap.ARIAL;
  } else if (leaf.brush_script_mt) {
    style.fontFamily = fontMap.BRUSH_SCRIPT_MT;
  } else if (leaf.courier_new) {
    style.fontFamily = fontMap.COURIER_NEW;
  } else if (leaf.garamond) {
    style.fontFamily = fontMap.GARAMOND;
  } else if (leaf.georgia) {
    style.fontFamily = fontMap.GEORGIA;
  } else if (leaf.tahoma) {
    style.fontFamily = fontMap.TAHOMA;
  } else if (leaf.times_new_roman) {
    style.fontFamily = fontMap.TIMES_NEW_ROMAN;
  } else if (leaf.trebuchet_ms) {
    style.fontFamily = fontMap.TREBUCHET_MS;
  } else if (leaf.verdana) {
    style.fontFamily = fontMap.VERDANA;
  } 
  return style;
}

const styleFontSize = (leaf: any) => {

  let style: any = {};
  for (var prop in leaf) {
    if (prop.startsWith('sz')) {
      const size = parseInt(prop.substring(2));
      style.fontSize = size;
    }
  }
  return style;
}
