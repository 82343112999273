import { Button, Form, Col, Input, Modal, Radio, Row, Space, RadioChangeEvent, Checkbox, notification } from "antd";
import { FaPlus } from "react-icons/fa6";
import { TdiNodeModel } from "../../model/tdi-node-model";
import { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useLazyGetScriptsQuery, useSaveScriptMutation } from "../../redux/api/scripts";

interface ConverterProps {
    open: boolean,
    node?: TdiNodeModel,
    onCancel: () => void,
    onOk: (values: any) => void
}

export const ConverterModal: React.FC<ConverterProps> = ({open, node, onOk, onCancel}): JSX.Element => {

    const [converterType, setConverterType] = useState<string>();
    const [required, setRequired] = useState<boolean | undefined>(false);
    const [showRef, setShowRef] = useState<boolean | undefined>(false);
    const [isSaveRefOpen, setSaveRefOpen] = useState<boolean | undefined>(false);
    const [scriptName, setScriptName] = useState<string>();
    const [scripts, setScripts] = useState<any[]>([]);
    const [script, setScript] = useState<any>({});
    
    const [form] = Form.useForm();

    const [getScripts] = useLazyGetScriptsQuery();
    const [saveScript] = useSaveScriptMutation();
    
    useEffect(() => {
        setConverterType(node?.converterType);
        setRequired(node?.required);
        setShowRef(false);
        form.setFieldValue('source', node?.source);
        form.setFieldValue('enums', node?.enums);
        form.setFieldValue('script', node?.script);
        form.setFieldValue('required', node?.required);
        
    }, [node]);

    const saveConverter = (values:any) => {
        const convertedData = {...values, converterType, required};
        onOk(convertedData);
    }

    const onModeChange = (e: RadioChangeEvent) => {
        setConverterType(e.target.value);
        setShowRef(false);
    }

    const onRequiredChecked = (e: CheckboxChangeEvent) => {
        setRequired(e.target.checked);
    }

    const showScriptReferences = async () => {

        setScript({});
        try {
            const scripts = await getScripts(null).unwrap();
            setScripts(scripts);
            setShowRef(true);
        } catch (e) {
            console.log(e);
            notification.error({
                message: "Saving Script Failed!",
                description: `An error occurred while saving the script.`
            });
        }
    }

    const handleSaveScript = async () => {

        const body = {
            name: scriptName,
            source: form.getFieldValue('source'),
            script: form.getFieldValue('script')
        }

        try {
            const script = await saveScript(body).unwrap();
            let list = [...scripts, script];
            setScripts(list);
            notification.success({
                message: "Script Saved!",
                description: `The script has been created.`
            });
        } catch (e) {
            console.log(e);
            notification.error({
                message: "Saving Script Failed!",
                description: `An error occurred while saving the script.`
            });
        }

        setSaveRefOpen(false);
    }

    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setScriptName(e.currentTarget.value);
    }
    
    const selectScript = (script: any) => {
        console.log('select script', script);
        setScript(script);
    }

    const copyScriptToClipboard = () => {
        form.setFieldValue('source', script.source);
        form.setFieldValue('script', script.script);
        // navigator.clipboard.writeText();
    }

    return (<Modal
        title={'Converter'}
        closable={false}
        open={open}
        onCancel={onCancel}
        onOk={() => form.submit()}
        destroyOnClose={true}
        width={showRef ? 1040 : 420}
    >
        <div className="converter-container">
            <Form 
                form={form} 
                onFinish={saveConverter} 
                className="data-mapping-modal">
                <div className="required-field">
                    <Checkbox checked={required} onChange={onRequiredChecked}>This is a required field *</Checkbox>
                </div>
                <Form.Item style={{visibility: 'hidden'}} name="source">
                    <Input/>
                </Form.Item>
                <Radio.Group onChange={(e) => onModeChange(e)} value={converterType}>
                    <Space direction="vertical" size="large" style={{width: '100%'}}>
                        <Radio value="NONE">No Conversion needed</Radio>
                        <Radio value={"ENUM"}>Enumeration Types</Radio>
                        {converterType === 'ENUM' && <Form.List name="enums">
                        {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field) => {
                            return (<Form.Item
                                required={false}
                                key={field.key}>
                                <Row gutter={8}>
                                    <Col span={10}>
                                        <Form.Item
                                            name={[field.name, 'source']}
                                            validateTrigger={['onBlur']}
                                            rules={[{required: true, whitespace: true, message: "*"}]}
                                        >
                                            <Input placeholder="Source" autoComplete="off"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item
                                            name={[field.name, 'target']}
                                            validateTrigger={['onBlur']}
                                            rules={[{required: true, whitespace: true, message: "*"}]}
                                        >
                                            <Input placeholder="Target" autoComplete="off"/>
                                        </Form.Item>
                                    </Col>
                                    
                                    <Col span={4} className="flex flex-1 justify-center" style={{marginTop: '.25rem'}}>
                                        <Button 
                                            type="link" 
                                            onClick={() => {remove(field.name);}}
                                        >
                                            Remove
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>)}
                            )}
                            <Form.Item>
                                <Button
                                    style={{marginTop: '1rem'}}
                                    icon={<FaPlus/>}
                                    type="link"
                                    onClick={() => {
                                        add();
                                    }}>
                                    Add Enum Type
                                </Button>
                            </Form.Item>
                        </>
                        )}
                        </Form.List>}
                        <Radio value={"SCRIPT"}>Script</Radio>
                        {converterType === 'SCRIPT'
                        && <>
                            <div className="controls">
                                <Button type="primary" onClick={showScriptReferences}>Show Script References</Button>
                                <Button type="primary" onClick={() => setSaveRefOpen(true)}>Save As</Button>
                            </div>
                            <Form.Item
                                required={true}
                                validateTrigger={['onBlur']}
                                rules={[{required: true, whitespace: true, message: "Script is required"}]}
                                name="script">
                                <TextArea style={{height: '12rem'}} placeholder="Script"/>
                            </Form.Item>
                        </>}
                        <br/>
                    </Space>
                </Radio.Group>
            </Form>
            {showRef &&
                <div className="ref-container">
                    <div className="sidebar">
                        {scripts.map((item: any) => {
                            return <div 
                                className={item.id === script.id ? 'script-item active' : 'script-item'}  
                                onClick={() => selectScript(item)}>
                                {item.name}
                            </div>
                        })}
                    </div>
                    <div className="script-container">
                        <div className="source-container">
                            <label>Ref Source: </label><Input className="source" value={script.source} readOnly={true}/>
                        </div>
                        <TextArea className="script" value={script.script}/>
                        <div className="copy">
                            <Button type="primary" 
                                onClick={copyScriptToClipboard} 
                                disabled={null == script.script}>
                                Copy Script
                            </Button>
                        </div>
                    </div>
                </div>
            }

            <Modal
                title={'Save Script As'}
                open={isSaveRefOpen}
                width={420}
                footer={null}
                destroyOnClose={true}
                onOk={handleSaveScript}
                onCancel={() => setSaveRefOpen(false)}>
                <br/>
                <label>Script Name</label>
                <Input onChange={handleNameChange} />
                <br/>
                <div className="save-as-controls">
                    <Button type="primary" onClick={handleSaveScript}>Ok</Button>
                    <Button onClick={() => setSaveRefOpen(false)}>Cancel</Button>
                </div>
            </Modal>
        </div>
    </Modal>)
}
